<template>
  <div class="wrap">
    <!-- <TablePage ref="tablePage" v-model="options"> </TablePage> -->
    <TablePage
      ref="tablePage"
      key="tablePage"
      v-show="showList"
      v-model="options"
      @handleEvent="handleEvent"
    />
    <TablePage
      ref="tablePageDetail"
      key="tablePageDetail"
      v-if="!showList"
      v-model="optionsDetail"
      @handleEvent="handleEvent"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listBookStatisticsByStoreGoodsDay,
  sumListBookStatisticsByStoreGoodsDay,
  listBookStatisticsByStoreGoods,
  sumListBookStatisticsByStoreGoods,
} from "@/api/system/store"; //仓库
import { storeGoodsDetail } from "@/api/storeReport"; //接口api
export default {
  name: "stockDayQuery",
  components: { TablePage },
  data() {
    return {
      showList: true,
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
        },
        uuid: "d658c143-d75b-8928-774b-05ff6aa40358",
        body: {},
        title: "销售订单汇总表",
        labelWidth: "80",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBookDate: a, endBookDate: b }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip:
              "全部、商品编号、商品名称、条码、商品类别名称、商品类别编号、仓库名称、仓库编号",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编号", group: "goodsQuery" },
              { filter: "goodsNames", label: "商品名称", group: "goodsQuery" },
              { filter: "barcodes", label: "条码", group: "goodsQuery" },
              { filter: "categoryNames", label: "商品类别名称", group: "categoryQuery" },
              { filter: "categoryNos", label: "商品类别编号", group: "categoryQuery" },
              { filter: "storeNames", label: "仓库名称", group: "storeQuery" },
              { filter: "storeNos", label: "仓库编号", group: "storeQuery" },
            ],
          },
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabsColumns: [
          {
            title: "每日库存明细查询",
            // defaultBody: { searchType: "goods" },
            type: "goods",
            exportOption: {
              show: true,
              exportApi: "exportListBookStatisticsByStoreGoodsDay",
              exportName: "每日库存明细查询",
              timeout: 60 * 5 * 1000,
            },
            uuid: "d658c143-d75b-8928-774b-05ff6aa40358",
            getListApi: listBookStatisticsByStoreGoodsDay,
            getSummaryApi: sumListBookStatisticsByStoreGoodsDay,
            columns: [
              {
                prop: "dayDate",
                label: "单据日期",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
                formatter: (v, row) => row.storeInfo.storeName,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                link: true,
                click: "oepnDetail",
                formatter: (v, row) => row.goodsInfo.goodsNo,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsName,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.barcode,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
                formatter: (v, row) => row.goodsCategoryInfo.categoryName,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsSpec,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.unitName,
              },
              {
                prop: "firstQty",
                label: "期初数",
                minWidth: 150,
              },
              {
                prop: "firstMoney",
                label: "期初金额",
                minWidth: 150,
              },
              {
                prop: "inQty",
                label: "入库数",
                minWidth: 150,
              },
              {
                prop: "inMoney",
                label: "入库金额",
                minWidth: 150,
              },
              {
                prop: "outQty",
                label: "出库数",
                minWidth: 150,
              },
              {
                prop: "outMoney",
                label: "出库金额",
                minWidth: 150,
              },
              {
                prop: "finalQty",
                label: "期末数",
                minWidth: 150,
              },
              {
                prop: "finalMoney",
                label: "期末金额",
                minWidth: 150,
              },
            ],
            summary: [
              "finalMoney",
              "finalQty",
              "outMoney",
              "outQty",
              "inMoney",
              "inQty",
              "firstMoney",
              "firstQty",
            ],
          },
          {
            title: "每日库存汇总查询",
            // defaultBody: { searchType: "goods" },
            type: "goods",
            exportOption: {
              show: true,
              exportApi: "exportListBookStatisticsByStoreGoods",
              exportName: "每日库存汇总查询",
              timeout: 60 * 5 * 1000,
            },
            uuid: "cc48715e-770a-867a-ece5-8216c881fcf9",
            getListApi: listBookStatisticsByStoreGoods,
            getSummaryApi: sumListBookStatisticsByStoreGoods,
            columns: [
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
                formatter: (v, row) => row.storeInfo.storeName,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsNo,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsName,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.barcode,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
                formatter: (v, row) => row.goodsCategoryInfo.categoryName,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.goodsSpec,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
                formatter: (v, row) => row.goodsInfo.unitName,
              },
              {
                prop: "firstQty",
                label: "期初数",
                minWidth: 150,
              },
              {
                prop: "firstMoney",
                label: "期初金额",
                minWidth: 150,
              },
              {
                prop: "inQty",
                label: "本期入库数",
                minWidth: 150,
              },
              {
                prop: "inMoney",
                label: "本期入库金额",
                minWidth: 150,
              },
              {
                prop: "outQty",
                label: "本期出库数",
                minWidth: 150,
              },
              {
                prop: "outMoney",
                label: "本期出库金额",
                minWidth: 150,
              },
              {
                prop: "finalQty",
                label: "期末数",
                minWidth: 150,
              },
              {
                prop: "finalMoney",
                label: "期末金额",
                minWidth: 150,
              },
            ],
            summary: [
              "finalMoney",
              "finalQty",
              "outMoney",
              "outQty",
              "inMoney",
              "inQty",
              "firstMoney",
              "firstQty",
            ],
          },
        ],
      },
      optionsDetail: {
        uuid: "1838cd6e-0a0a-a7e7-9f7b-c09ce682ea13",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
        },
        body: {},
        title: "商品库存明细",
        tableTitle: "商品库存明细",
        rowKey: "billId",
        delTableHeigth: 0,
        list: [],
        getSummaryApi: undefined,
        defaultBody: {
          goodsId: "",
          storeId: "",
          batchNo: "",
        },
        getListApi: storeGoodsDetail,
        getDataKey: (e) => {
          this.optionsDetail.search = this.optionsDetail.search.map((item) => ({
            ...item,
            model: e.data.mainReport[item.filter],
          }));
          this.$refs.tablePageDetail.summaryDateils = e.data?.mainReport || {};
          return { list: e.data.tableDataInfo.rows, total: e.data.tableDataInfo.total };
        },
        search: [
          {
            type: "input",
            label: "仓库",
            tip: " ",
            model: "",
            filter: "storeName",
            disabled: true,
          },
          {
            type: "input",
            label: "商品编码",
            tip: " ",
            model: "",
            filter: "goodsNo",
            disabled: true,
          },
          {
            type: "input",
            label: "商品名称",
            tip: " ",
            model: "",
            filter: "goodsName",
            disabled: true,
          },
          {
            type: "input",
            label: "商品条码",
            tip: " ",
            model: "",
            filter: "barcode",
            disabled: true,
          },
          {
            type: "input",
            label: "商品批号",
            tip: " ",
            model: "",
            filter: "batchNo",
            disabled: true,
          },
          {
            type: "input",
            label: "规格",
            tip: " ",
            model: "",
            filter: "goodsSpec",
            disabled: true,
          },
          {
            type: "input",
            label: "基础单位",
            tip: " ",
            model: "",
            filter: "unitName",
            disabled: true,
          },
          {
            type: "input",
            label: "成本价",
            tip: " ",
            model: "",
            filter: "costPrice",
            disabled: true,
          },
          {
            type: "input",
            label: "标准进价",
            tip: " ",
            model: "",
            filter: "purPrice",
            disabled: true,
          },
          {
            type: "input",
            label: "标准售价",
            tip: " ",
            model: "",
            filter: "salePrice",
            disabled: true,
          },
          {
            type: "input",
            label: "期初数",
            tip: " ",
            model: "",
            filter: "firstQty",
            disabled: true,
          },
          {
            type: "input",
            label: "期初金额",
            tip: " ",
            model: "",
            filter: "firstMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "入库数",
            tip: " ",
            model: "",
            filter: "inQty",
            disabled: true,
          },
          {
            type: "input",
            label: "入库金额",
            tip: " ",
            model: "",
            filter: "inMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "出库数",
            tip: " ",
            model: "",
            filter: "outQty",
            disabled: true,
          },
          {
            type: "input",
            label: "出库金额",
            tip: " ",
            model: "",
            filter: "outMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "期末数",
            tip: " ",
            model: "",
            filter: "finalQty",
            disabled: true,
          },
          {
            type: "input",
            label: "期末金额",
            tip: " ",
            model: "",
            filter: "finalMoney",
            disabled: true,
          },
          { type: "button", tip: "关闭", btnType: "primary", click: "close" },
        ],
        summary: [
          "firstQty",
          "firstMoney",
          "inQty",
          "inMoney",
          "outQty",
          "outMoney",
          "finalQty",
          "finalMoney",
        ],
        columns: [
          { label: "记账时间", prop: "auditTime", fixed: true, minWidth: 120 },
          {
            label: "单据编号",
            prop: "billNo",
            minWidth: 120,
            fixed: true,
            link: true,
            click: "getBillData",
          },
          { label: "单据日期", prop: "billDate", minWidth: 100 },
          { label: "单据类型", prop: "billTypeName", minWidth: 120 },
          { label: "商品批号", prop: "batchNo", minWidth: 100 },
          // { label: '标准进价', prop: 'purPrice', minWidth: 120 },
          // { label: '标准售价', prop: 'salePrice', minWidth: 100 },
          { label: "期初数", prop: "firstQty", minWidth: 80 },
          { label: "期初金额", prop: "firstMoney", minWidth: 80 },
          { label: "入库数", prop: "inQty", minWidth: 80 },
          { label: "入库金额", prop: "inMoney", minWidth: 100 },
          { label: "出库数", prop: "outQty", minWidth: 80 },
          { label: "出库金额", prop: "outMoney", minWidth: 100 },
          { label: "期末数", prop: "finalQty", minWidth: 80 },
          { label: "期末金额", prop: "finalMoney", minWidth: 100 },
          {
            label: "入库",
            prop: "billDate",
            children: [
              { label: "采购", prop: "purQty", minWidth: 100 },
              { label: "采购金额", prop: "purMoney", minWidth: 100 },
              { label: "仓库调入数", prop: "purMoney", minWidth: 100 },
              { label: "仓库调入金额", prop: "purMoney", minWidth: 100 },
              { label: "库存调整入库数", prop: "adjustInQty", minWidth: 130 },
              { label: "库存调整入库金额", prop: "adjustInMoney", minWidth: 130 },
              { label: "销售退货数", prop: "saleBackQty", minWidth: 100 },
              { label: "销售退货金额", prop: "saleBackMoney", minWidth: 100 },
              { label: "仓库盘盈数", prop: "fullQty", minWidth: 100 },
              { label: "仓库盘盈金额", prop: "fullMoney", minWidth: 100 },
              { label: "生产退料数", prop: "backMaterialQty", minWidth: 100 },
              { label: "生产退料金额", prop: "backMaterialMoney", minWidth: 100 },
              { label: "产品入库数", prop: "adjustInQty", minWidth: 120 },
              { label: "产品入库金额", prop: "adjustInQty", minWidth: 120 },
              { label: "组装入库数", prop: "combinInQty", minWidth: 130 },
              { label: "组装入库金额", prop: "combinOutMoney", minWidth: 130 },
              { label: "拆卸入库数", prop: "stripInQty", minWidth: 100 },
              { label: "拆卸入库金额", prop: "stripInMoney", minWidth: 100 },
              { label: "门店采购数", prop: "shopPurQty", minWidth: 100 },
              { label: "门店采购金额", prop: "shopPurMoney", minWidth: 100 },
              { label: "门店生产数", prop: "shopProduceInQty", minWidth: 100 },
              { label: "门店生产金额", prop: "shopProduceInMoney", minWidth: 100 },
              { label: "门店调入数", prop: "shopMoveInQty", minWidth: 100 },
              { label: "门店调入金额", prop: "shopMoveInMoney", minWidth: 100 },
              { label: "门店盘盈数", prop: "shopFullQty", minWidth: 100 },
              { label: "门店盘盈金额", prop: "shopFullMoney", minWidth: 100 },
              { label: "现烤生产数", prop: "freshlyBakedProduceInQty", minWidth: 100 },
              {
                label: "现烤生产金额",
                prop: "freshlyBakedProduceInMoney",
                minWidth: 100,
              },
              { label: "现烤调入数", prop: "freshlyBakedMoveInQty", minWidth: 100 },
              { label: "现烤调入金额", prop: "freshlyBakedMoveInMoney", minWidth: 100 },
              { label: "现烤盘盈数", prop: "freshlyBakedFullQty", minWidth: 100 },
              { label: "现烤盘盈金额", prop: "freshlyBakedFullMoney", minWidth: 100 },
              { label: "裱花生产数", prop: "decorationProduceInQty", minWidth: 100 },
              { label: "裱花生产金额", prop: "decorationProduceInMoney", minWidth: 100 },
              { label: "裱花调入数", prop: "decorationMoveInQty", minWidth: 100 },
              { label: "裱花调入金额", prop: "decorationMoveInMoney", minWidth: 100 },
              { label: "裱花盘盈数", prop: "decorationFullQty", minWidth: 100 },
              { label: "裱花盘盈金额", prop: "decorationFullMoney", minWidth: 100 },
            ],
          },
          {
            label: "出库",
            prop: "billDate",
            children: [
              { label: "销售数", prop: "saleQty", minWidth: 100 },
              { label: "销售金额", prop: "saleMoney", minWidth: 100 },
              { label: "仓库调出数", prop: "saleMoney", minWidth: 100 },
              { label: "仓库调出金额", prop: "saleMoney", minWidth: 100 },
              { label: "库存调整出库数", prop: "adjustOutQty", minWidth: 130 },
              { label: "库存调整出库金额", prop: "adjustOutMoney", minWidth: 130 },
              { label: "采购退货数", prop: "purBackQty", minWidth: 100 },
              { label: "采购退货金额", prop: "purBackMoney", minWidth: 100 },
              { label: "仓库盘亏数", prop: "moveOutQty", minWidth: 100 },
              { label: "仓库盘亏金额", prop: "moveOutQty", minWidth: 100 },
              { label: "生产领料数", prop: "leadMaterialQty", minWidth: 100 },
              { label: "生产领料金额", prop: "leadMaterialMoney", minWidth: 100 },
              { label: "组装出库数", prop: "combinOutQty", minWidth: 120 },
              { label: "组装出库金额", prop: "combinOutMoney", minWidth: 120 },
              { label: "拆卸出库数", prop: "stripOutQty", minWidth: 130 },
              { label: "拆卸出库金额", prop: "stripOutMoney", minWidth: 130 },
              { label: "门店销售数", prop: "shopSaleQty", minWidth: 100 },
              { label: "门店销售金额", prop: "shopSaleMoney", minWidth: 100 },
              { label: "门店报废数", prop: "shopWasteQty", minWidth: 100 },
              { label: "门店报废金额", prop: "shopWasteMoney", minWidth: 100 },
              { label: "门店调出数", prop: "shopMoveOutQty", minWidth: 120 },
              { label: "门店调出金额", prop: "shopMoveOutMoney", minWidth: 120 },
              { label: "门店原料消耗数", prop: "shopLeadMaterialQty", minWidth: 130 },
              { label: "门店原料消耗金额", prop: "shopLeadMaterialMoney", minWidth: 130 },
              { label: "门店盘亏数", prop: "shopLoseQty", minWidth: 100 },
              { label: "门店盘亏金额", prop: "shopLoseMoney", minWidth: 100 },
              { label: "门店零售数", prop: "shopRetailQty", minWidth: 100 },
              { label: "门店零售金额", prop: "shopRetailMoney", minWidth: 100 },
              { label: "现烤报废数", prop: "freshlyBakedWasteQty", minWidth: 100 },
              { label: "现烤报废金额", prop: "freshlyBakedWasteMoney", minWidth: 100 },
              { label: "现烤调出数", prop: "freshlyBakedMoveOutQty", minWidth: 100 },
              { label: "现烤调出金额", prop: "freshlyBakedMoveOutMoney", minWidth: 100 },
              {
                label: "现烤原料消耗数",
                prop: "freshlyBakedLeadMaterialQty",
                minWidth: 130,
              },
              {
                label: "现烤原料消耗金额",
                prop: "freshlyBakedLeadMaterialMoney",
                minWidth: 130,
              },
              { label: "现烤盘亏数", prop: "freshlyBakedLoseQty", minWidth: 100 },
              { label: "现烤盘亏金额", prop: "freshlyBakedLoseMoney", minWidth: 100 },
              { label: "裱花报废数", prop: "decorationWasteQty", minWidth: 100 },
              { label: "裱花报废金额", prop: "decorationWasteMoney", minWidth: 100 },
              { label: "裱花调出数", prop: "decorationMoveOutQty", minWidth: 100 },
              { label: "裱花调出金额", prop: "decorationMoveOutMoney", minWidth: 100 },
              {
                label: "裱花原料消耗数",
                prop: "decorationLeadMaterialQty",
                minWidth: 130,
              },
              {
                label: "裱花原料消耗金额",
                prop: "decorationLeadMaterialMoney",
                minWidth: 130,
              },
              { label: "裱花盘亏数", prop: "decorationLoseQty", minWidth: 100 },
              { label: "裱花盘亏金额", prop: "decorationLoseMoney", minWidth: 100 },
            ],
          },
          { label: "备注", prop: "remark" },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "close": // 关闭二级弹窗
          this.showList = true;
          this.optionsDetail.list = [];
          break;
        case "oepnDetail": //获取点击商品信息
          console.log("到这里了吗", row);
          this.optionsDetail.defaultBody = { bookId: row.bookId };
          // const res = await storeGoodsDetail({
          //   bookId: row.bookId,
          //   ...this.optionsDetail.pagination,
          // });
          // this.optionsDetail.list = res.data?.tableDataInfo?.rows || [];
          // this.optionsDetail.search = this.optionsDetail.search.map((item) => ({
          //   ...item,
          //   model: res.data.mainReport[item.filter],
          // }));
          // this.optionsDetail.summaryData = res.data?.mainReport || {};
          // this.optionsDetail.loading = false;
          this.showList = false;
          // await this.$nextTick();
          // this.$refs?.tablePageDetail?.getSummaryDateils();
          break;
        default:
          break;
      }
    },
  },
};
</script>
