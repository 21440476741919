var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _vm.showList
        ? _c("TablePage", {
            key: "tablePage",
            ref: "tablePage",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options,
              callback: function ($$v) {
                _vm.options = $$v
              },
              expression: "options",
            },
          })
        : _vm._e(),
      !_vm.showList
        ? _c("TablePage", {
            key: "tablePageDetail",
            ref: "tablePageDetail",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.optionsDetail,
              callback: function ($$v) {
                _vm.optionsDetail = $$v
              },
              expression: "optionsDetail",
            },
          })
        : _vm._e(),
      _c("StockQueryDetail", {
        attrs: {
          openStockQuery: _vm.openStockQuery,
          goodsDetailData: _vm.goodsDetailData,
        },
        on: {
          "update:openStockQuery": function ($event) {
            _vm.openStockQuery = $event
          },
          "update:open-stock-query": function ($event) {
            _vm.openStockQuery = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }